/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/componentsV2/sections/Header"
import { Footer } from "src/sections/landing"
import WidthWrapper from "src/components/widthWrapper"

const DataProcessingDoc = () => (
  <div className="leading-loose pb-24 pt-90/16 md:pt-40">
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | Data Processing</title>
      <meta name="description" content="" />
    </Helmet>
    <h1>DATA PROCESSING ADDENDUM</h1>
    <p>
      This Data Processing Addendum (“Addendum”) is part of the Master Service
      Agreement ("Agreement") between AirMason Inc. ("Processor") and the
      customer entity that is a party to the Agreement as a Customer
      ("Controller") (each a "Party"; together "the Parties").
    </p>
    <br />
    <h2>Table of Contents</h2>
    <ol>
      <li>
        <a href="#definitions">DEFINITIONS</a>
      </li>
      <li>
        <a href="#roles-and-responsibilities">ROLES AND RESPONSIBILITIES</a>
      </li>
      <li>
        <a href="#data-processing-details">DATA PROCESSING DETAILS</a>
      </li>
      <li>
        <a href="#security">SECURITY</a>
      </li>
      <li>
        <a href="#subprocessing">SUBPROCESSING</a>
      </li>
      <li>
        <a href="#data-subject-rights">DATA SUBJECT RIGHTS</a>
      </li>
      <li>
        <a href="#data-transfers">DATA TRANSFERS</a>
      </li>
      <li>
        <a href="#data-breach-notification">DATA BREACH NOTIFICATION</a>
      </li>
      <li>
        <a href="#audit-rights">AUDIT RIGHTS</a>
      </li>
      <li>
        <a href="#return-or-deletion-of-data">RETURN OR DELETION OF DATA</a>
      </li>
      <li>
        <a href="#liability">LIABILITY</a>
      </li>
      <li>
        <a href="#term-and-termination">TERM AND TERMINATION</a>
      </li>
    </ol>
    <br />

    <div id="definitions" className="data-process-content">
      <h2>DEFINITIONS</h2>
      <ol>
        <li>
          Agreement refers to the Master Service Agreement between AirMason and
          the Controller, including any and all schedules, exhibits, and annexes
          thereto.
        </li>
        <li>
          Controller refers to the client of AirMason who determines the
          purposes and means of Processing of Personal Data.
        </li>
        <li>
          Data Subject means any identified or identifiable natural person who
          is the subject of the Personal Data.
        </li>
        <li>
          GDPR means the General Data Protection Regulation (Regulation (EU)
          2016/679).
        </li>
        <li>
          Personal Data means any information relating to a Data Subject that
          Controller provides to Processor under the Agreement, which is
          protected under the Data Protection Laws.
        </li>
        <li>
          Processing means any operation or set of operations performed upon
          Personal Data, whether or not by automated means, such as collection,
          recording, organization, structuring, storage, adaptation or
          alteration, retrieval, consultation, use, disclosure by transmission,
          dissemination or otherwise making available, alignment or combination,
          restriction, erasure, or destruction.
        </li>
        <li>
          Processor refers to AirMason, a company that Processes Personal Data
          on behalf of the Controller.
        </li>
        <li>
          Data Protection Laws means all laws and regulations, including laws
          and regulations of the European Union, the European Economic Area,
          their member states, Switzerland, the United Kingdom, and the United
          States and its states, applicable to the Processing of Personal Data
          under the Agreement.
        </li>
        <li>
          Subprocessor means any third-party appointed by or on behalf of
          Processor to Process Personal Data on behalf of the Controller.
        </li>
        <li>
          Service refers to the provision by AirMason of its employee handbook
          creation platform that allows Controller to create, distribute, and
          manage digital employee handbooks. The Service includes but is not
          limited to the storage and processing of personal data related to
          Controller's employees, the ability to send automated reminders to
          employees for handbook signing, integration with Controller's existing
          HRIS systems, Single Sign-On (SSO) capabilities, and any other
          functionalities or services provided by AirMason under the Agreement.
        </li>
      </ol>
    </div>
    <br />
    <div id="roles-and-responsibilities" className="data-process-content">
      <h2>ROLES AND RESPONSIBILITIES</h2>
      <ol>
        <li>
          Data Controller: The Controller (the client of AirMason) determines
          the purposes and means of the Processing of Personal Data in
          connection with the use of the Service. The Controller is responsible
          for ensuring that the Personal Data it provides or directs to be
          provided to AirMason for Processing in connection with the Service is
          collected and transferred in compliance with applicable laws,
          including securing necessary permissions and consents where required.
        </li>
        <li>
          Data Processor: AirMason, as a Data Processor, Processes Personal Data
          on behalf of the Controller in accordance with the terms of the
          Agreement and this DPA, and will follow the Controller's instructions
          unless such instructions are not technically feasible, violate any
          applicable laws, or are not in alignment with the terms of the
          Agreement or this DPA.
        </li>
        <li>
          Data Subjects: Data Subjects are the identified or identifiable
          natural persons to whom the Personal Data pertains. These may include,
          for example, the Controller's employees or users, to whom AirMason may
          send automated reminders for handbook signing or provide other
          services as instructed by the Controller.
        </li>
        <li>
          Subprocessors: AirMason may engage Subprocessors to provide certain
          parts of the Service. AirMason will enter into written agreements with
          each Subprocessor that imposes data protection obligations to ensure a
          level of security appropriate to the risk associated with the
          Processing of Personal Data.
        </li>
        <li>
          Prohibited Data: The Controller shall not transfer or provide to
          AirMason any Personal Data that falls under special categories of data
          as defined by the GDPR or other applicable laws unless expressly
          agreed to in writing by AirMason. Such data may include, but is not
          limited to, sensitive Personal Data relating to racial or ethnic
          origin, political opinions, religious beliefs, trade union membership,
          physical or mental health or condition, sexual orientation, or
          criminal offences ("Prohibited Data"). AirMason is not designed to
          Process Prohibited Data and assumes no responsibility for such data
          provided by the Controller.
        </li>
      </ol>
    </div>
    <br />
    <div id="data-processing-details" className="data-process-content">
      <h2>DATA PROCESSING DETAILS</h2>
      <ol>
        <li>
          Purpose: AirMason shall Process Personal Data solely for the purpose
          of providing the Services stipulated in the Agreement, and for no
          other purpose, unless other purposes are expressly agreed upon in
          writing by the Parties.
        </li>
        <li>
          Processing Instructions: AirMason will only Process Personal Data in
          accordance with the Controller’s documented instructions. The
          Controller’s initial instructions to AirMason for the Processing of
          Personal Data are to perform the Services in accordance with the
          Agreement.
        </li>
        <li>
          Security Measures: AirMason shall implement appropriate technical and
          organizational measures to protect the Personal Data, taking into
          account the state of technology, the costs of implementation, the
          nature, scope, context, and purposes of Processing, as well as the
          risk to the rights and freedoms of natural persons.
        </li>
        <li>
          Confidentiality: AirMason shall ensure that persons authorized to
          Process the Personal Data have committed themselves to confidentiality
          or are under an appropriate statutory obligation of confidentiality.
        </li>
        <li>
          Assistance: AirMason shall, taking into account the nature of the
          Processing, assist the Controller by appropriate technical and
          organizational measures, insofar as this is possible, for the
          fulfilment of the Controller's obligation to respond to requests for
          exercising the data subject's rights.
        </li>
      </ol>
    </div>
    <br />
    <div id="security" className="data-process-content">
      <h2>SECURITY</h2>
      <ol>
        <li>
          Processor shall take all measures required pursuant to Article 32 of
          the GDPR. Processor will implement and maintain appropriate technical
          and organizational measures to protect the personal data against
          unauthorized or unlawfol processing and against accidental loss,
          destruction, damage, theft, alteration, or disclosure. These measures
          shall be appropriate to the harm which might resolt from any
          unauthorized or unlawfol processing, accidental loss, destruction,
          damage or theft of the personal data and having regard to the nature
          of the personal data which is to be protected. Such measures may
          include, as appropriate:
          <ul className="disc">
            <li>Pseudonymization and encryption of personal data</li>
            <li>
              Ability to ensure the ongoing confidentiality, integrity,
              availability, and resilience of processing systems and services
            </li>
            <li>
              Ability to restore the availability and access to personal data in
              a timely manner in the event of a physical or technical incident
            </li>
            <li>
              Process for regolarly testing, assessing, and evaluating the
              effectiveness of technical and organizational measures for
              ensuring the security of the processing
            </li>
          </ul>
        </li>
        <li>
          Processor has implemented security measures which include, but are not
          limited to, secure data transfer, data segregation, firewall
          protections, system monitoring, logging and alerting, intrusion
          detection and prevention, vulnerability management, and data
          encryption at rest and in transit. Furthermore, Processor has in place
          incident management processes and regularly carries out system
          backups.
        </li>
        <li>
          In addition to its obligations under Article 32 of the GDPR, Processor
          commits to provide reasonable assistance to Controller in ensuring
          compliance with the obligations pursuant to Articles 32 to 36 of the
          GDPR, taking into account the nature of processing and the information
          available to the Processor.
        </li>
        <li>
          Details about specific technical and organizational measures
          implemented by Processor may be further outlined in Annex 2 –
          Technical and Organizational Measures.
        </li>
      </ol>
    </div>
    <br />
    <div id="subprocessing" className="data-process-content">
      <h2>SUBPROCESSING</h2>
      <ol>
        <li>
          The Controller authorizes the Processor to engage sub-processors as
          necessary to deliver the Service, subject to the requirements in this
          Section. The Processor shall maintain an up-to-date list of its
          sub-processors, which is available for
          <a
            href="https://storage.googleapis.com/airmason-handbook-us/AirMason%20Subprocessors.pdf"
            target="_blank"
          >
            review here
          </a>
          .
        </li>
        <li>
          The Processor shall enter into a written agreement with each
          sub-processor containing data protection obligations at least as
          protective as those in this Addendum, to the extent applicable to the
          nature of the Service provided by such sub-processor. In such cases,
          the Processor will ensure that such sub-processors are bound by
          obligations consistent with this Addendum and that they implement
          appropriate technical and organizational measures to comply with Data
          Protection Laws.
        </li>
        <li>
          In the event of any proposed change in sub-processor, the Processor
          will notify the Controller at least 30 days in advance, allowing the
          Controller to object to such changes. If the Controller objects to the
          new sub-processor and the Processor chooses to retain that
          sub-processor, the Controller may terminate this Agreement by
          providing written notice to the Processor.
        </li>
        <li>
          The Processor remains responsible at all times for the compliance of
          each sub-processor with the obligations of this Addendum and for any
          acts or omissions of the sub-processor that cause Processor to breach
          any of its obligations under this Addendum.
        </li>
      </ol>
    </div>
    <br />
    <div id="data-subject-rights" className="data-process-content">
      <h2>DATA SUBJECT RIGHTS</h2>
      <ol>
        <li>
          In accordance with Data Protection Laws, data subjects have certain
          rights with respect to their Personal Data. As part of its Service,
          AirMason provides the Controller with various self-service features,
          which may be used to assist it in connection with its obligations
          under Data Protection Laws pertaining to such rights.
        </li>
        <li>
          These features enable the Controller to retrieve, correct, delete, or
          restrict the use of Customer Data, including but not limited to:
          <ul className="disc">
            <li>
              Retrieval of all Signature Data and Employee Contacts through .csv
              export
            </li>
            <li>PDF export of Employee Handbooks</li>
          </ul>
        </li>
        <li>
          In view of the nature of the processing, AirMason shall provide
          reasonable assistance to the Controller, to the extent possible, to
          enable the Controller to comply with its data protection obligations
          with respect to data subject rights under Data Protection Laws.
        </li>
        <li>
          If any request pertaining to data subject rights is made directly to
          AirMason, AirMason will not respond to such communication directly,
          except as necessary (e.g., to direct the data subject to contact the
          Controller) or as legally required, without prior authorization from
          the Controller.
        </li>
        <li>
          If AirMason is required to respond to such a request and the
          Controller is identifiable from the request, AirMason will promptly
          notify the Controller and provide a copy of the request, unless
          legally prohibited from doing so.
        </li>
        <li>
          Nothing in the Agreement, including this DPA, shall restrict or
          prevent AirMason from responding to any data subject or data
          protection authority requests concerning Personal Data for which
          AirMason is a controller.
        </li>
      </ol>
    </div>
    <br />
    <div id="data-transfers" className="data-process-content">
      <h2>DATA TRANSFERS</h2>
      <ol>
        <li>
          In connection with the provision of the Service, AirMason may store
          and process Personal Data in the United States, European Union, or
          Canada, in accordance with the customer's location and preferences.
        </li>
        <li>
          For US-based customers, Personal Data will be stored and processed
          within US-based Google Cloud data centers, and no data is transferred
          outside the United States. For EU-based customers, Personal Data is
          stored and processed within the Brussels-based Google Cloud data
          center, and no data is transferred outside the EU. For Canadian
          customers who have specifically requested a single tenant VPC,
          Personal Data is stored and processed within Canadian-based data
          centers.
        </li>
        <li>
          AirMason, in collaboration with Google Cloud's robust policies and
          mechanisms, ensures that the storage and processing of Personal Data
          complies with all applicable Data Protection Laws. In cases where
          Personal Data is transferred, it is limited to what is necessary to
          provide the Service. Access to Personal Data by AirMason's staff and
          subcontractors is limited to those who need to provide the Service.
        </li>
        <li>
          Although AirMason does not directly implement specific mechanisms such
          as Standard Contractual Clauses or Privacy Shield certifications, the
          adherence to such policies is enforced by Google Cloud. AirMason will
          continue to adhere to all relevant data protection laws and ensure
          that Personal Data is stored and processed securely, and in compliance
          with the relevant laws.
        </li>
      </ol>
    </div>
    <br />
    <div id="data-breach-notification" className="data-process-content">
      <h2>DATA BREACH NOTIFICATION</h2>
    </div>
    <p>
      In the event of a data breach, AirMason has a thorough internal procedure
      in place to identify, contain, eradicate, recover from, and conduct
      post-incident analysis of the breach. These procedures ensure an immediate
      response and minimize the impact on the affected parties. AirMason
      employees are required to report any unauthorized or suspicious activity
      they notice in relation to production systems or related communication
      systems.
    </p>

    <br />
    <p>
      Upon the discovery of a data breach, AirMason promptly commences an
      in-depth, five-phase incident response procedure. These phases include:
    </p>
    <ol>
      <li>Identification and Triage</li>
      <li>Containment</li>
      <li>Eradication</li>
      <li>Recovery</li>
      <li>Post-Incident Analysis</li>
    </ol>
    <br />
    <p>
      AirMason's incident response team conducts all stages of this process,
      documenting all measures taken during each phase, including start and end
      times of all efforts.
    </p>
    <br />
    <p>
      For significant events, such as the application of critical security
      patches to the operating system or network, AirMason commits to notifying
      clients within seven days of the event.
    </p>
    <br />
    <p>
      In the event of a data breach that results in a risk to the rights and
      freedoms of individuals, AirMason will notify affected customers promptly
      via email. This notification will contain details including:
    </p>
    <ol>
      <li>A description of the nature of the breach,</li>
      <li>The types of data that were involved,</li>
      <li>Measures taken by AirMason to address the breach,</li>
      <li>
        Recommendations for steps users can take to protect themselves, such as
        resetting their passwords.
      </li>
    </ol>
    <br />
    <p>
      In the aftermath of a data breach, AirMason will assist its customers in
      mitigating potential adverse effects. As part of its support, AirMason
      will provide assistance to users in resetting their passwords.
    </p>
    <p>
      AirMason is committed to safeguarding your data and will continue to
      enhance and update its data breach procedures to ensure the highest level
      of security. We believe in transparency and will do our best to keep our
      customers informed and supported in the event of a data breach.
    </p>
    <br />
    <div id="audit-rights" className="data-process-content">
      <h2>AUDIT RIGHTS</h2>
    </div>
    <p>
      AirMason is committed to maintaining the highest level of data protection
      and understands the importance of transparency and accountability in this
      regard. However, due to the nature of our service, the sensitive
      information we handle, and the potential disruption to our operations, we
      currently do not allow customers or their authorized third-party auditors
      to conduct audits of our data processing activities.
    </p>
    <br />
    <p>
      We assure our customers that our data handling and processing practices
      are designed to be compliant with the relevant Data Protection Laws. To
      this end, we have rigorous internal policies and procedures in place to
      ensure our compliance, and we regularly review and update these policies
      in line with the best practices and legal requirements.
    </p>
    <br />
    <p>
      To provide transparency and demonstrate our compliance, we make available
      relevant documentation such as privacy policies, security practices, and
      data processing addendums. Additionally, we can provide a SOC 2 report
      upon request, which is conducted by an independent auditor and provides a
      thorough review of our data handling practices.
    </p>
    <br />
    <p>
      We understand the importance of verifying compliance with data protection
      laws and regulations. Therefore, if a customer has specific compliance
      requirements or queries, they should communicate these to us, and we will
      strive to address them in the best possible manner.
    </p>
    <br />
    <div id="return-or-deletion-of-data" className="data-process-content">
      <h2>RETURN OR DELETION OF DATA</h2>
      <p className="-ml-38/16">
        Upon termination of the Services or upon a written request by the
        Customer, AirMason shall return or delete Customer Personal Data in its
        possession. AirMason undertakes the following steps to ensure the secure
        handling of the Customer's Personal Data:
      </p>
      <ol>
        <li>
          Deletion After Termination: Following the termination of the
          Customer's contract, AirMason retains Customer Personal Data for a
          period of 90 days. During this period, the Customer can request a data
          export by email. After the lapse of this 90-day period, the Personal
          Data is automatically deleted.
        </li>
        <li>
          Deletion Upon Request: Customers can request the deletion of their
          Personal Data at any time by sending an email to AirMason. We aim to
          comply with such deletion requests within 7 days.
        </li>
        <li>
          Anonymization of Data: In cases where there is a soft delete (via the
          dashboard, under regular operations), AirMason may anonymize or
          de-identify Customer Personal Data rather than deleting it entirely.
          This is done to maintain the integrity of the data while ensuring that
          no personal identifiable information remains.
        </li>
        <li>
          Data Deletion Procedures: AirMason uses a multi-step process to ensure
          the complete deletion of data. This includes the deletion of data from
          our live servers, followed by the deletion from our backup servers.
          Once deletion is completed, the Customer will receive an email
          notification confirming the deletion.
        </li>
        <li>
          Exceptions: There are no exceptions to the data return or deletion
          policy except as required by applicable laws. In such cases, only the
          minimum necessary information is retained.
        </li>
      </ol>
    </div>
    <br />
    <p>
      This Return or Deletion of Data policy ensures AirMason's commitment to
      respect the privacy and confidentiality of the Customer's Personal Data.
    </p>
    <br />
    <div id="liability" className="data-process-content">
      <h2>LIABILITY</h2>
      <ol>
        <li>
          The aggregate liability of each party and all of its Affiliates
          arising out of or related to this Data Processing Addendum (including
          the Standard Contractual Clauses, where applicable) shall be subject
          to the exclusions and limitations of liability set forth in each
          Service Level Agreement governing the relationship between the
          Customer and AirMason.
        </li>
        <li>
          Any claims made against AirMason under or in connection with this Data
          Processing Addendum (including, where applicable, the Standard
          Contractual Clauses) shall be brought solely by the Customer entity
          that is a party to the Agreement.
        </li>
        <li>
          This limitation of liability reflects the allocation of risk between
          the Parties and is an essential element of the bargain between the
          Parties.
        </li>
      </ol>
    </div>
    <br />
    <div id="term-and-termination" className="data-process-content">
      <h2>TERM AND TERMINATION</h2>
      <ol>
        <li>
          Term: This DPA shall commence on the Effective Date and shall continue
          in effect until termination of the Agreement or until AirMason ceases
          to process Customer Data on behalf of the Customer, whichever is
          later.
        </li>
        <li>
          Termination: This DPA shall terminate automatically upon termination
          or expiration of the Agreement in accordance with its terms. Any
          obligation imposed on AirMason under this DPA regarding the handling
          and protection of Customer Data shall survive termination or
          expiration of this DPA to the extent AirMason continues to possess or
          control such Customer Data.
        </li>
        <li>
          Breach: In the event of a breach of the terms of this DPA by AirMason,
          the Customer may, without prejudice to any other rights or remedies
          and on giving at least fifteen (15) days written notice to AirMason,
          terminate this DPA and the Agreement if AirMason fails to cure such
          breach within the notice period.
        </li>
        <li>
          Right to Terminate: AirMason reserves the right to terminate this DPA
          and the Agreement upon thirty (30) days written notice if the Customer
          is in breach of any term of this DPA and fails to cure such breach
          within the notice period.
        </li>
        <li>
          Data After Termination: Upon termination or expiration of this DPA,
          AirMason will cease all processing of Customer Data and will, at the
          request of the Customer, return or delete Customer Data in its
          possession, in accordance with Section 10 of this DPA.
        </li>
      </ol>
    </div>
  </div>
)

const Privacy = ({}) => {
  return (
    <React.Fragment>
      <Header />
      <WidthWrapper>
        <DataProcessingDoc />
      </WidthWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default Privacy
